import Banner from "@/components/Cliente/Banner/Banner.vue";
import YoutubeVideo from "@/components/Cliente/YoutubeVideo/YoutubeVideo.vue";
import Infografo from "@/views/cliente/Infografo/Infografo.vue"

export default {
	components: {
		Banner,
		YoutubeVideo,
		Infografo
	},
	data() {
		return {
			dataClicked: undefined,
			obrigacaoAtiva: undefined,
			backHistory: [],
			dialog: false,
			obHome: null,
			obCalendario: null,
			obEvento: null,
			obEventos: [],
			arrayEvents: null,
			blLoadingNoticias: true,
			arBanners: [],
			arNoticias: [],
			arVideos: [],
			dias: "",
			date: new Date().toISOString().substr(0, 10),
			rolagemMenu: false,
			strTitulo: "<span class='nome'>ECONET EDITORA </span></br>"
		};
	},
	// +
	// "<span class='frase'>Junto com você na Declaração do Imposto de Renda 2020</span>"

	created() {
		this.obterDados();
	},

	watch: {
		"dialog"() {
			console.log(this.dialog);
			if(!this.dialog) {
				this.obrigacaoAtiva = undefined;
			}
		}
	},

	mounted() {
		window.addEventListener("scroll", this.esconderMenuLateral);
	},
	computed: {
		eventos() {
			if (this.arrayEvents == null) {
				return [];			}
			return this.arrayEvents.map(evento => {
				return evento.data;
			});
		}
	},
	methods: {
		getData(d) {
			return d.split("-")
					.reverse()
					.join("/");
		},
		showObrigacaoDialog(obrigacao) {
			this.obrigacaoAtiva = obrigacao;
		},
		titulosMenuLateral(titulo) {
			return this.$root.$utils.criaSlug(titulo);
		},
		obterDados() {
			this.$root.$api.get('home/obterDados').then(response => {
				this.arrayEvents = response.retorno.eventos;
				this.arNoticias = response.retorno.noticias;
				this.arVideos = response.retorno.videos;
				this.obHome = response.retorno.home;
				this.obCalendario = response.retorno.calendario;
				this.arBanners = response.retorno.banners;
				this.blLoadingNoticias = false;
			})
		},
		esconderMenuLateral() {
			this.rolagemMenu = window.scrollY > 400 ? "block" : "none";
		},
		backDia() {
			let dia = this.backHistory.pop();
			this.eventosDataCalandario(dia);
		},
		getTexto(noticia) {
			if(noticia.resumo && noticia.resumo != "") {
				return noticia.resumo
			}else {
				return noticia.texto;
			}
		},
		fechaDialog() {
			this.dialog = false
			this.backHistory = [];
			this.obrigacaoAtiva = undefined;
		},
		eventosDataCalandario(data, hasBack = false) {
			this.obEvento = this.arrayEvents.find(
				evento => data == evento.data
			);
			this.obEventos = this.arrayEvents.filter(
				evento => data == evento.data
			);
			// console.log(this.arrayEvents, data);
			if (typeof this.obEvento !== "undefined") {
				this.dataClicked = data.split("-")
					.reverse()
					.join("/");
				this.dialog = true;

				if(hasBack) {
					this.backHistory.push(data);
				}
			}
		},

		verificaEventos(data) {
			return this.arrayEvents.find(evento => data == evento.data);
		},

		obterCorEvento(data) {
			if (!this.arrayEvents || !data) {
				return null;
			}

			if (
				typeof this.arrayEvents.find(evento => data == evento.data) !=
				"undefined" &&
				this.arrayEvents.find(evento => data == evento.data)
				.id_tipo_evento == 1
			) {
				return "#41B883";
			} else if (
				typeof this.arrayEvents.find(evento => data == evento.data) !=
				"undefined" &&
				this.arrayEvents.find(evento => data == evento.data)
				.id_tipo_evento == 2
			) {
				return "#ff8200";
			}

			return "#98BFFF";
		}
	}
};